/** @format */
/* global PubSub */

import debounce from 'debounce-promise'
import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import SelectAsync from 'react-select/async'
import cn from 'classnames'
import memoize from 'memoize-one'

import { ContentContainerIdContext } from 'src/lib/context'

import I18n from 'src/i18n'
import colors from 'src/lib/colors'
import { RemoveIcon } from 'components/shared/svg-icons'

export class SelectField extends Component {
  static contextType = ContentContainerIdContext

  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),

    options: PropTypes.array,
    defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    loadOptions: PropTypes.func,
    async: PropTypes.bool,

    clearable: PropTypes.bool,
    clearValueText: PropTypes.string,

    searchable: PropTypes.bool,

    modifier: PropTypes.string,
    color: PropTypes.string,

    hasError: PropTypes.bool,
    errorText: PropTypes.string,

    onOpen: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    return !props.loadOptions && props.options !== state.options
      ? { ...state, options: props.options || [] }
      : state
  }

  state = {
    isLoading: false,
    options: this.props.options || [],
    optionsLoaded: !this.props.loadOptions,
    up: false,
  }

  componentDidMount() {
    this.mounted = true

    setTimeout(() => {
      const contentContainerId = this.context

      if (contentContainerId && this.selectRef.current) {
        const element = document.getElementById(contentContainerId)

        if (!element) {
          return
        }

        const wrapRect = element.getBoundingClientRect()
        const currentRect = this.selectRef.current.select.controlRef.getBoundingClientRect()
        const up = currentRect.bottom + 180 > wrapRect.bottom

        this.mounted && this.setState({ up })
      }
    }, 0)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  portalElm = document.getElementById('rselect-portal')
  selectRef = createRef()

  asyncLoadOptions = debounce(
    (inputValue, callback) => this.props.loadOptions(inputValue, callback),
    250,
    { leading: false }
  )

  maybeLoadOptions = () => {
    if (!this.state.optionsLoaded && this.props.loadOptions) {
      this.mounted && this.setState({ isLoading: true })

      const res = this.props.loadOptions('', this.optionsLoaded)

      res instanceof Promise && res.then(this.optionsLoaded)
    }

    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  optionsLoaded = result => {
    const options = result instanceof Array ? result : result.options

    this.mounted && this.setState({ options, isLoading: false, optionsLoaded: true })
  }

  notifyOpen = ev => {
    const contentContainerId = this.context

    this.timeout = setTimeout(() => {
      this.timeout = null
      this.mounted &&
        this.open &&
        PubSub.publish('general/reactSelect', { event: 'open', contentContainerId })
    }, 50)

    this.open = true
    this.props.onOpen && this.props.onOpen(ev)
  }

  notifyClose = ev => {
    const contentContainerId = this.context

    this.timeout
      ? (this.timeout = clearTimeout(this.timeout))
      : PubSub.publish('general/reactSelect', { event: 'close', contentContainerId })

    this.open = false
    this.props.onOpen && this.props.onClose(ev)
  }

  focus() {
    this.selectRef.current.select.select.focus()
  }

  render() {
    const contentContainetId = this.context
    const {
      async = false,
      className,
      clearable = false,
      components: replacedComponents = {},
      errorText,
      hasError,
      hintText = '',
      modifier = null,
      multiple = false,
      searchable = false,
      usePortal = !!contentContainetId,
      ...rest
    } = this.props
    const selectClass = 'reactSelect'

    let value = this.props.value

    if (![void 0, null].includes(value) && !(value instanceof Object)) {
      value = this.state.options.find(o => o.value == value)
    }

    const CSelect = async ? SelectAsync : Select

    return (
      <div className={cn('SelectField', { [`SelectField--${modifier}`]: modifier }, className)}>
        <CSelect
          ref={this.selectRef}
          backspaceRemovesValue={false}
          className={cn(selectClass, {
            [`${selectClass}--clearable`]: clearable,
            [`${selectClass}--${modifier}`]: modifier,
          })}
          classNamePrefix={selectClass}
          components={{ ClearIndicator, Option, ...replacedComponents }}
          isClearable={clearable}
          isLoading={async ? void 0 : this.state.isLoading}
          isMulti={multiple}
          isSearchable={searchable}
          loadingMessage={() => I18n.t('labels.loading')}
          menuPortalTarget={usePortal ? this.portalElm : void 0}
          menuPlacement={this.state.up ? 'top' : 'auto'}
          noOptionsMessage={() => I18n.t('messages.select.no_options')}
          placeholder={hintText}
          styles={memoizedCustomStyles(this.props.color)}
          theme={THEME}
          {...rest} /* order of ...rest matter */
          loadOptions={this.asyncLoadOptions}
          defaultOptions={async ? this.state.options : void 0}
          options={async ? void 0 : this.state.options}
          value={value}
          onFocus={async ? void 0 : this.maybeLoadOptions}
          onMenuOpen={this.notifyOpen}
          onMenuClose={this.notifyClose}
        />

        {hasError && errorText && <span className="error-text">{errorText}</span>}
      </div>
    )
  }
}

export class InlineSelectField extends Component {
  selectRef = createRef()

  focus() {
    this.selectRef.current.focus()
  }

  render() {
    return <SelectField ref={this.selectRef} modifier="inline" {...this.props} />
  }
}

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <RemoveIcon size={14} fill={null} />
  </components.ClearIndicator>
)

const Option = props => {
  let prefix = []

  if (props.data.depth && props.data.depth > 0) {
    for (let i = 0; i < props.data.depth; i++) {
      prefix.push([
        <i key={prefix.length} className="icon-spacer" />,
        <i key={prefix.length + 1} className="icon-spacer" />,
      ])
    }

    prefix.push([
      <i key={prefix.length} className="icon-caret-right" />,
      <i key={prefix.length + 1} className="icon-spacer" />,
    ])
  }

  return (
    <components.Option {...props}>
      <span>{prefix}</span>
      <span className={props.data.modifier}>{props.data.label}</span>
    </components.Option>
  )
}

const THEME = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    neutral0: colors.white, // control background
    neutral20: colors.inputBorder, // control border
    neutral30: colors.inputBorderHover, // border of hovered control
    neutral40: colors.inputBorderHover, // hovered arrow
    neutral50: colors.inputPlaceholder, // placeholder text color
    neutral60: colors.inputBorder, // arrow indicator focused
    neutral80: colors.inputBorderHover, // arrow indicator focused and hovered
    primary: colors.lightBg, // selected item background and border of focused and hovered control
    primary25: colors.lightBg, // hovered option
    primary50: colors.lightBg,
    primary75: colors.lightBg,
  },
})

const customStyles = color => ({
  container: provided => provided,

  control: provided => ({
    ...provided,
    position: 'initial',
    boxShadow: 0,
    minHeight: '32px',
    transition: 'border-color .2s ease-in-out, color .2s ease-in-out',
    cursor: 'pointer',
  }),

  clearIndicator: provided => ({
    ...provided,
    // position: 'absolute',
    // right: '-18px',
    padding: '2px',
    cursor: 'pointer',
    fill: provided.color,
    transition: 'fill .2s ease-in-out',

    '&:hover': {
      fill: colors.inputBorderHover,
    },
  }),

  dropdownIndicator: provided => ({
    ...provided,
    padding: '2px',
  }),

  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'transparent',
  }),

  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    zIndex: 999,
  }),

  menu: provided => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    maxWidth: '125%',
    // backgroundColor: colors.white,
  }),

  menuPortal: provided => ({
    ...provided,
    width: 'auto',
    minWidth: provided.width,
    zIndex: 999,
  }),

  option: provided => ({
    ...provided,
    color: color ? color : colors.black,
    lineHeight: '1em',
    padding: '6px 8px',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  }),

  singleValue: provided => ({
    ...provided,
    color: color ? color : colors.black,
  }),

  multiValueLabel: provided => ({
    ...provided,
    color: color ? color : colors.black,
  }),

  multiValueRemove: provided => ({
    ...provided,
    color: color ? color : colors.black,
  }),

  valueContainer: provided => ({
    ...provided,
    padding: '2px 4px',
  }),
})

const memoizedCustomStyles = memoize(customStyles)
