/** @format */

import debounce from 'lodash/debounce'
import { Component, createRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { DayPickerSingleDateController } from 'react-dates'
import styled from 'styled-components'
import cn from 'classnames'
import moment from 'moment'

import I18n from 'src/i18n'
import colors from 'src/lib/colors'
import { ContentContainerIdContext } from 'src/lib/context'

import { Button } from 'components/shared/forms'

import { RemoveIcon } from 'components/shared/svg-icons'
import { SelectField } from '.'

import 'react-dates/lib/css/_datepicker.css'

export class DateField extends Component {
  static contextType = ContentContainerIdContext

  static propTypes = {
    className: PropTypes.string,
    clearable: PropTypes.bool,
    fromDate: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    fromDate: moment('2012-01-01'),
  }

  state = {
    focused: false,
    up: false,
  }

  componentDidMount() {
    this.mounted = true

    setTimeout(() => {
      const contentContainerId = this.context

      if (contentContainerId && this.componentRef.current) {
        const element = document.getElementById(contentContainerId)

        if (!element) {
          return
        }

        const wrapRect = element.getBoundingClientRect()
        const currentRect = this.componentRef.current.getBoundingClientRect()
        const up = currentRect.bottom + 180 > wrapRect.bottom

        this.mounted && this.setState({ up })
      }
    }, 0)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentRef = createRef()
  portalElm = document.getElementById('react-portals')

  onClear = ev => {
    ev.stopPropagation()

    this.props.onChange(null)
  }

  onFocus = ev => {
    ev.stopPropagation()

    this.setState({ focused: true })
  }

  onToggleFocus = debounce(() => this.setState({ focused: !this.state.focused }), 100)

  checkRange = date => {
    return this.props.fromDate
      ? moment(this.props.fromDate).startOf('day').valueOf() >= moment(date).valueOf()
      : false
  }

  month(month) {
    const { fromDate } = this.props

    return moment(month).isBefore(fromDate) ? fromDate.month() : month.month()
  }

  years() {
    const { fromDate } = this.props
    const minYear = fromDate.year()
    const maxYear = moment().year() + 4

    let years = []

    for (let i = maxYear; i >= minYear; i--) {
      years.push(i)
    }

    return years
  }

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <MonthElement>
        <SelectField
          maxMenuHeight={165}
          modifier="month"
          options={moment.months().map((label, value) => ({ value, label }))}
          searchable
          usePortal={false}
          value={this.month(month)}
          onChange={m => onMonthSelect(month, m.value)}
        />
        <SelectField
          maxMenuHeight={165}
          modifier="year"
          options={this.years().map(value => ({ label: value, value }))}
          searchable
          usePortal={false}
          value={month.year()}
          onChange={y => onYearSelect(month, y.value)}
        />
      </MonthElement>
    )
  }

  setDate = type => () => {
    if (type === 'today') {
      this.props.onChange(moment())
      this.setState({ focused: false })
    }
  }

  renderCalednarInfo = () => {
    return (
      <CalendarInfo>
        <Button
          variant="outlined"
          onClick={this.setDate('today')}
          disabled={this.props.skipWeekends && this.isDayBlocked(moment())}
        >
          {I18n.t('labels.today')}
        </Button>
      </CalendarInfo>
    )
  }

  isDayBlocked = date => {
    return date.isoWeekday() == 6 || date.isoWeekday() == 7
  }

  renderPicker({ onChange, skipWeekends, ...rest }) {
    const elm = this.componentRef.current
    const rect = elm.getBoundingClientRect()

    let left = rect.left

    if (rect.left + 280 > window.innerWidth) {
      left = window.innerWidth - 280
    }

    return (
      <DateFieldMenu style={{ left: left - rect.left }}>
        <DayPickerSingleDateController
          daySize={32}
          focused={this.state.focused}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          // interactions
          isOutsideRange={this.checkRange}
          renderMonthElement={this.renderMonthElement}
          renderCalendarInfo={this.renderCalednarInfo}
          // callbacks
          onDateChange={onChange}
          onFocusChange={this.onToggleFocus}
          onOutsideClick={this.onToggleFocus}
          isDayBlocked={skipWeekends ? this.isDayBlocked : undefined}
          {...rest}
        />
      </DateFieldMenu>
    )
  }

  renderPortal(rest) {
    const elm = this.componentRef.current
    const rect = elm.getBoundingClientRect()
    const scrollDistance = window.pageYOffset
    const offset = this.state.up ? rect.top + scrollDistance - 296 : rect.top + scrollDistance + 34
    const position = { left: rect.left, top: offset }

    return createPortal(
      <DateFieldPortal style={position}>{this.renderPicker(rest)}</DateFieldPortal>,
      this.portalElm
    )
  }

  render() {
    const contentContainerId = this.context
    const {
      clearable,
      fromDate, // eslint-disable-line no-unused-vars
      modifier,
      placeholder,
      // usePortal = !!contentContainerId,
      usePortal = true,
      ...rest
    } = this.props
    const { focused } = this.state

    return (
      <StyledDateField
        className={cn('DateField', modifier,
          {
            'm-focused': focused,
            'DateField--past': rest.date < moment().startOf('day').valueOf(),
          }
        )}
        ref={this.componentRef}
      >
        <Control className={cn(modifier, { 'm-focused': focused })} onClick={this.onFocus}>
          {!rest.date && placeholder}
          {rest.date?.format(I18n.t('date.moment_formats.default'))}

          {clearable && rest.date && (
            <Clearable className={modifier} onClick={this.onClear}>
              <RemoveIcon />
            </Clearable>
          )}
        </Control>

        {focused ? (usePortal ? this.renderPortal(rest) : this.renderPicker(rest)) : null}
      </StyledDateField>
    )
  }
}

export const InlineDateField = props => <DateField {...props} modifier="m-inline" />

const Clearable = styled.div`
  position: absolute;
  right: 6px;

  svg {
    fill: ${colors.inputBorder};
    transition: fill 0.2s ease-in-out;
  }

  &:hover {
    svg {
      fill: ${colors.text};
    }
  }

  &.m-inline {
    right: 4px;
  }

  @media print {
    display: none !important;
  }
`

const Control = styled.div`
  position: relative;
  display: inline-flex;
  width: 112px;
  height: 32px;
  padding: 0 6px;
  align-items: center;
  white-space: nowrap;
  border: 1px solid ${colors.inputBorder};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.inputBorderHover};
  }

  &.m-inline {
    border: 1px solid transparent;
    border-bottom: 1px solid ${colors.inputBorder};

    &:hover {
      border-bottom: 1px solid ${colors.inputBorderHover};
    }
  }

  &.m-focused,
  &.m-focused:hover {
    border-color: ${colors.inputBorderFocus};

    &.m-inline {
      border-color: transparent transparent ${colors.inputBorderFocus};
    }
  }
`

const DateFieldMenu = styled.div`
  position: absolute;
  z-index: 10;

  .DayPicker {
    margin-top: 5px;
  }
`

const DateFieldPortal = styled.div`
  position: absolute;
  z-index: 999;
`

const StyledDateField = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;
`

const MonthElement = styled.div`
  display: flex;
  position: relative;
  top: -4px;
  justify-content: space-evenly;
  margin: 0 1.6em;
  z-index: 9999;

  .SelectField {
    font-size: 1rem;
    margin: 0 2px;

    .reactSelect {
      position: relative;

      &__control {
        min-height: 28px;
      }

      &__value-container {
        padding: 0 2px;
      }

      &__single-value {
        max-width: calc(100% - 2px);
        color: ${colors.text};
      }

      &__menu {
        position: absolute;
        z-index: 200;
      }
    }

    &--month {
      width: 6.5em;
    }

    &--year {
      width: 4.4em;
    }
  }
`

const CalendarInfo = styled.div`
  display: flex;
  gap: 1em;
  justify-content: left;
  position: relative;
  top: -0.5em;
  padding-left: 21px;
  padding-bottom: 0.4rem;

  button {
    color: ${colors.text};
    font-weight: 300;
  }
`
