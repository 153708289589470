/** @format */
/* global app */

import 'regenerator-runtime/runtime'

import 'whatwg-fetch'
import Promise from 'promise-polyfill'
if (!window.Promise) {
  window.Promise = Promise
}

import './vendor/react-ujs'

require('src/i18n/index')
import I18n from 'src/i18n'

window.React = require('react')
window.ReactDOM = require('react-dom')
window.UserPassword = require('components/accounts/UserPassword').default

document.addEventListener('DOMContentLoaded', function() {
  I18n.defaultLocale = app.defaultLocale
  I18n.locale = app.locale

  window.ReactRailsUJS.mountComponents()
})
